import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import FilterDropdown from '../../filter-dropdown';
import styles from './radio-button-filter.module.scss';
import { RadioButtonFilterProps } from './radio-button-filter.types';

const formControlLabelSx = { '& .MuiTypography-root': { fontSize: '14px', lineHeight: '24px', fontWeight: '400' } };

export default function RadioButtonFilter(props: RadioButtonFilterProps) {
    const { label, options, value, onChange } = props;
    return (
        <FilterDropdown label={label}>
            <RadioGroup
                value={value}
                onChange={onChange}>
                {options.map((option, index) => (
                    <FormControlLabel
                        key={index}
                        sx={formControlLabelSx}
                        value={option.value}
                        control={<Radio className={styles.iconColor} />}
                        label={option.component}
                    />
                ))}
            </RadioGroup>
        </FilterDropdown>
    );
}
