import { Avatar, Box } from '@mui/material';
import priceDivider from '../../../functions/price-divider';
import styles from './airline-option.module.scss';
import { AirlineOptionProps } from './airline-option.types';

export default function AirlineOption(props: AirlineOptionProps) {
    const { avatar, label, count, cheapest } = props.data;
    return (
        <Box className={styles.checkboxLabel}>
            <Box className={styles.airline}>
                <Box className={styles.img}>
                    <Avatar
                        sx={{ width: '24px', height: '24px' }}
                        alt='Remy Sharp'
                        src={avatar}
                    />
                </Box>
                <Box className={styles.name}>
                    {/* ({count})  */}
                    {label}
                </Box>
            </Box>
            <Box className={styles.price}>{priceDivider(cheapest.toFixed(2))}</Box>
        </Box>
    );
}
