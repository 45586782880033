import { EnumFlight, Offer, SearchFlightsRequest, SupportedCurrency } from '../../services/flights/flight.types';
import { AirlineTranslateResponse, PlaceTranslationResponse } from '../../services/places/place-search-translate.types';
import { apiTranslateAirline, apiTranslatePlaceCode } from '../../services/places/places';
import { ParamsDataType, ValueAirlineFilterType, ValueStopCountFilterType } from './flights.types';
import queryString from 'query-string';
import CheckboxFilter from '../common/filter-dropdown/view/checkbox-filter/checkbox-filter';
import AirlineOption from '../common/filter-dropdown/components/airline-option/airline-option';
import { ReactElement } from 'react';
import RadioButtonFilter from '../common/filter-dropdown/view/radio-button-filter/radio-button-filter';
import { Filter } from '../common/hook/use-filters';
import { FlightSearchData } from './search-fields-flights/search-fields-flights.types';

export const flightFilters = (
    getAirlineLogo: (code: string) => string,
    airportTranslate: (code: string, translateField: 'city' | 'airport' | 'country') => string,
    exchangePrice: (price: number, priceCurrency: SupportedCurrency) => any,
    airlineTranslate: (code: string) => string,
    currency: SupportedCurrency,
    languageJson: any
): Filter[] => {
    return [
        {
            key: 'airlineFilter',
            reducer: (previousItem: Offer[] | null, currentItem: Offer) => {
                let airlines: { avatar: string; name: string; price?: number }[] = [];
                currentItem.paths.forEach(path => {
                    airlines = airlines.concat(
                        path.segments.map(segment => ({
                            avatar: getAirlineLogo(segment.airlineCode),
                            name: segment.airlineCode,
                            price: exchangePrice(currentItem.payablePrice.totalPrice, SupportedCurrency.USD),
                        }))
                    );
                });
                if (previousItem) return previousItem.concat(Array.from(new Set(airlines.map(airline => JSON.stringify(airline)))).map(airline => JSON.parse(airline)));
                else return Array.from(new Set(airlines.map(airline => JSON.stringify(airline)))).map(airline => JSON.parse(airline));
            },
            filter: (item: Offer, value: ValueAirlineFilterType[]) => {
                let itemsModified: string[] = [];
                item.paths.forEach(path => {
                    itemsModified = itemsModified.concat(path.segments.map(segment => segment.airlineCode));
                });
                if (value?.length > 0) return value?.find(val => itemsModified.includes(val.value)) ? true : false;
                else return true;
            },
            render: (items: { avatar: string; name: string; price?: number }[] | null, value: ValueAirlineFilterType[], setValue: Function) => {
                let itemsModified: any[] = [];
                let categorizedAirlines: { [key: string]: ValueAirlineFilterType } = {};
                items?.forEach(item => {
                    if (categorizedAirlines.hasOwnProperty(item.name)) {
                        categorizedAirlines[item.name].count += 1;
                        if (item.price && categorizedAirlines[item.name].cheapest > item.price) {
                            categorizedAirlines[item.name].cheapest = item.price;
                        }
                    } else {
                        categorizedAirlines[item.name] = { avatar: '', count: 0, label: '', value: '', cheapest: 0 };
                        categorizedAirlines[item.name].avatar = item.avatar;
                        categorizedAirlines[item.name].label = airlineTranslate(item.name) || item.name;
                        categorizedAirlines[item.name].value = item.name;
                        categorizedAirlines[item.name].count += 1;
                        categorizedAirlines[item.name].cheapest = item?.price || 0;
                    }
                });
                for (const key in categorizedAirlines) {
                    if (value?.find(val => val.value === categorizedAirlines[key].value)) itemsModified.push({ value: true, data: categorizedAirlines[key], component: <AirlineOption data={categorizedAirlines[key]} /> });
                    else itemsModified.push({ value: false, data: categorizedAirlines[key], component: <AirlineOption data={categorizedAirlines[key]} /> });
                }

                // to sort airlines which have not profile to the last of array.
                // it needs a new condition on its label and check equality to item.name or
                // - put a flag to manage it.
                // const itemsWhichHaveNotAvatar: any[] = itemsModified.filter(item => !item.data.avatar);
                // itemsModified.forEach((itemModified, index) => {
                //     if (!itemModified.data.avatar) {
                //         itemsModified.splice(index, 1)
                //     }
                // })
                // itemsModified.concat(itemsWhichHaveNotAvatar)

                const setOptionValue = (e: any, optionData: ValueAirlineFilterType) => {
                    const copyValue = [...value];
                    if (e.target.checked) setValue([...copyValue, optionData]);
                    else {
                        const clearedValue = copyValue.filter(value => value.value !== optionData.value);
                        setValue(clearedValue);
                    }
                };
                return (
                    <CheckboxFilter
                        subLabel={currency}
                        label={`${languageJson.common.airline}(${Object.keys(categorizedAirlines).length})`}
                        options={itemsModified}
                        onChange={(e, optionData: ValueAirlineFilterType) => setOptionValue(e, optionData)}
                    />
                );
            },
        },
        {
            key: 'stopFilter',
            reducer: (previousItem: ValueStopCountFilterType | null, currentItem: Offer) => {
                let offerStops: ValueStopCountFilterType = { offerId: currentItem.offerId, stopCount: -1 };
                currentItem.paths.forEach(path => {
                    offerStops = { ...offerStops, stopCount: offerStops.stopCount + path.segments.length };
                });
                return offerStops;
            },
            filter: (item: Offer, value: number[]) => {
                let count = -1;
                item.paths.forEach(path => {
                    count += path.segments.length;
                });
                if (value.length === 0 || value[0] === -1) {
                    return true;
                } else if (value[0] < 2 && value[0] > -1) {
                    if (count === value[0]) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    if (count >= 2) {
                        return true;
                    } else {
                        return false;
                    }
                }
            },
            render: (items: ValueStopCountFilterType | null, value: number[], setValue: Function) => {
                const options: {
                    value: number;
                    component: ReactElement;
                }[] = [
                    {
                        value: -1,
                        component: <>{languageJson.common.all}</>,
                    },
                    {
                        value: 0,
                        component: <>{languageJson.common.direct_flight}</>,
                    },
                    {
                        value: 1,
                        component: <>{languageJson.common.one_stop}</>,
                    },
                    {
                        value: 2,
                        component: <>{languageJson.common.two_stops_more}</>,
                    },
                ];
                return (
                    <RadioButtonFilter
                        label={languageJson.common.stop_number}
                        value={value[0] ?? -1}
                        options={options}
                        onChange={(e, valueRadio: string) => setValue([parseInt(valueRadio) ?? -1])}
                    />
                );
            },
        },
        {
            key: 'airportFilter',
            reducer: (previousItem: string[] | null, currentItem: Offer) => {
                let airports: string[] = [];
                currentItem.paths.forEach(path => {
                    path.segments.forEach(segment => {
                        airports = airports.concat([segment.origin]);
                        airports = airports.concat([segment.destination]);
                    });
                });
                if (previousItem) return previousItem.concat(Array.from(new Set(airports)));
                else return Array.from(new Set(airports));
            },
            filter: (item: Offer, value: string[]) => {
                let airports: string[] = [];
                item.paths.forEach(path => {
                    path.segments.forEach(segment => {
                        airports = airports.concat([segment.origin]);
                        airports = airports.concat([segment.destination]);
                    });
                });
                airports = Array.from(new Set(airports));
                if (value?.length > 0) return value?.find(val => airports.includes(val)) ? true : false;
                else return true;
            },
            render: (items: string[] | null, value: string[], setValue: Function) => {
                let itemsModified: { value: boolean; data: { value: string; label: string }; component: ReactElement }[] = [];
                const itemsUnique = Array.from(new Set(items));
                itemsUnique.forEach(item => {
                    if (value?.find(val => val === item)) itemsModified.push({ value: true, data: { value: item, label: item }, component: <>{item + ' - ' + airportTranslate(item, 'city')}</> });
                    else itemsModified.push({ value: false, data: { value: item, label: item }, component: <>{item + ' - ' + airportTranslate(item, 'city')}</> });
                });
                const setOptionValue = (e: any, optionData: { value: string; label: string }) => {
                    const copyValue = [...value];
                    if (e.target.checked) setValue([...copyValue, optionData.value]);
                    else {
                        const clearedValue = copyValue.filter(value => value !== optionData.value);
                        setValue(clearedValue);
                    }
                };
                return (
                    <CheckboxFilter
                        label={languageJson.common.airport}
                        options={itemsModified}
                        onChange={(e, optionData: { value: string; label: string }) => setOptionValue(e, optionData)}
                    />
                );
            },
        },
    ];
};
export const makeGetTokenSearchFlightsBody = (urlData: FlightSearchData, language: string): SearchFlightsRequest => {
    const departureDateStartYear = urlData.rangeDate.start.year;
    const departureDateStartMonth = urlData.rangeDate.start.month;
    const departureDateStartDay = urlData.rangeDate.start.day;
    const departureDateEndYear = urlData.rangeDate.end?.year;
    const departureDateEndMonth = urlData.rangeDate.end?.month;
    const departureDateEndDay = urlData.rangeDate.end?.day;
    let body: SearchFlightsRequest = {
        // "lan": language,
        flightInfo: {
            flightType: urlData.tripType,
            cabinClass: urlData.flightClass,
            originDestinationInfo: [
                {
                    departureDate: `${departureDateStartYear}-${departureDateStartMonth}-${departureDateStartDay}`,
                    originCode: urlData.originDestination.origin.code.toUpperCase(),
                    originType: urlData.originDestination.origin.type,
                    destinationCode: urlData.originDestination.destination.code.toUpperCase(),
                    destinationType: urlData.originDestination.destination.type,
                },
            ],
        },
        passengerInfo: {
            adultCount: urlData.passengers.adult,
            childCount: urlData.passengers.child,
            infantCount: urlData.passengers.infant,
        },
    };
    if (urlData.tripType === EnumFlight.RETURN && urlData.rangeDate.end) {
        body.flightInfo.originDestinationInfo.push({
            departureDate: `${departureDateEndYear}-${departureDateEndMonth}-${departureDateEndDay}`,
            destinationCode: urlData.originDestination.origin.code.toUpperCase(),
            originType: urlData.originDestination.origin.type,
            originCode: urlData.originDestination.destination.code.toUpperCase(),
            destinationType: urlData.originDestination.destination.type,
        });
    }
    return body;
};
export const transformParamsToDataFlight = (query: string): FlightSearchData => {
    const queryData: any = queryString.parse(query.split('?')[1], { arrayFormat: 'index' });
    const queryDataTyped: ParamsDataType = queryData;
    const rangeStartYear = parseInt(queryDataTyped.start.split('-')[0]) || new Date().getFullYear();
    const rangeStartMonth = parseInt(queryDataTyped.start.split('-')[1]) || new Date().getMonth() + 1;
    const rangeStartDay = parseInt(queryDataTyped.start.split('-')[2]) || new Date().getDate();
    const data: FlightSearchData = {
        tripType: queryDataTyped.tripType,
        flightClass: queryDataTyped.flightClass,
        originDestination: {
            origin: {
                label: queryDataTyped.originLabel,
                code: queryDataTyped.originCode,
                type: queryDataTyped.originType,
                country: queryDataTyped.originCountry,
            },
            destination: {
                label: queryDataTyped.destinationLabel,
                code: queryDataTyped.destinationCode,
                type: queryDataTyped.destinationType,
                country: queryDataTyped.destinationCountry,
            },
        },
        passengers: {
            adult: parseInt(queryDataTyped.adult) || 1,
            child: parseInt(queryDataTyped.child) || 0,
            infant: parseInt(queryDataTyped.infant) || 0,
        },
        rangeDate: {
            start: { year: rangeStartYear, month: rangeStartMonth, day: rangeStartDay },
        },
    };
    if (queryDataTyped.end) {
        const rangeEndYear = parseInt(queryDataTyped.end.split('-')[0]) || new Date().getFullYear();
        const rangeEndMonth = parseInt(queryDataTyped.end.split('-')[1]) || new Date().getMonth() + 1;
        const rangeEndDay = parseInt(queryDataTyped.end.split('-')[2]) || new Date().getDate();
        data.rangeDate.end = { year: rangeEndYear, month: rangeEndMonth, day: rangeEndDay };
    }
    return data;
};

export const timeConverter = (duration: number, languageJson: any): string => {
    let day = Math.floor(duration / 1440);
    let hour = Math.floor((duration - day * 1440) / 60);
    let minutes = Math.floor(duration - day * 1440 - hour * 60);

    if (minutes === 60) {
        hour++;
        minutes = 0;
    }
    if (hour === 24) {
        day++;
        hour = 0;
    }

    return `${day > 0 ? day + ' ' + languageJson.common.day : ''}${hour > 0 ? (day > 0 ? ' & ' : '') + hour + ' ' + languageJson.common.hour : ''}${minutes > 0 ? (day > 0 || hour > 0 ? ' & ' : '') + minutes + ' ' + languageJson.common.minute : ''}`;
};

export const extractAirportCodes = (offers: Array<Offer>, urlData?: FlightSearchData): Array<string> => {
    const codes: Array<string> = [];
    offers.forEach(offer => {
        offer.paths.forEach(path => {
            path.segments.forEach(segment => {
                codes.push(segment.origin);
                codes.push(segment.destination);
            });
        });
    });
    // for top navigation showing origin and destination sometimes you chose a country but in api there is no any code of country and
    // there is just airport codes so at this case we have to have chosen origin and destination codes to send them for api and get
    // their information
    urlData && codes.push(urlData.originDestination.origin.code, urlData.originDestination.destination.code);
    return Array.from(new Set(codes));
};
export const extractAirlineCodes = (offers: Array<Offer>): Array<string> => {
    const codes: Array<string> = [];
    offers.forEach(offer => {
        offer.paths.forEach(path => {
            path.segments.forEach(segment => {
                codes.push(segment.airlineCode);
            });
        });
    });
    return Array.from(new Set(codes));
};
export const getAirlineInformation = async (airlineCodes: { airlineCodes: Array<string> }) => {
    if (airlineCodes.airlineCodes.length > 0)
        try {
            const data = await apiTranslateAirline(airlineCodes);
            return data.data;
        } catch (err) {
            console.log(err);
        }
};
export const getAirportInformation = async (airportCodes: { airportCodes: Array<string> }) => {
    if (airportCodes.airportCodes.length > 0)
        try {
            const data = await apiTranslatePlaceCode(airportCodes);
            return data.data;
        } catch (err) {
            console.log(err);
        }
};
export const translateAirlineCode = (code: string, locale: string | undefined, airlineCodesInfo: AirlineTranslateResponse[] | null): string => {
    const desireCode = airlineCodesInfo?.find(codeInfo => codeInfo.code === code);
    return desireCode?.airline_names.find(name => name.language === locale)?.value || '';
};
export const translateAirportCode = (code: string, translateField: 'airport' | 'city' | 'country', locale: string | undefined, airportCodesInfo: PlaceTranslationResponse[] | null): string => {
    const desireCode = airportCodesInfo?.find(codeInfo => codeInfo.code === code);
    if (translateField)
        switch (translateField) {
            case 'airport':
                return desireCode?.airport_names.find(name => name.language === locale)?.value || '';
            case 'city':
                return desireCode?.city.city_names.find(name => name.language === locale)?.value || '';
            case 'country':
                return desireCode?.city.country.country_names.find(name => name.language === locale)?.value || '';
            default:
                return '';
        }
    else return '';
};
export const getNewCodes = (codes: string[], storedCode: PlaceTranslationResponse[] | AirlineTranslateResponse[]): string[] => {
    const extractCode = storedCode.map(item => item.code);
    return codes.concat(extractCode).filter(code => codes.includes(code) && !extractCode.includes(code));
};
