import { Checkbox, FormControlLabel, Typography } from '@mui/material';
import FilterDropdown from '../../filter-dropdown';
import styles from './checkbox-filter.module.scss';
import { CheckboxFilterProps } from './checkbox-filter.types';
export default function CheckboxFilter(props: CheckboxFilterProps) {
    const { label, options, subLabel, onChange } = props;
    return (
        <FilterDropdown label={label}>
            <>
                {subLabel && (
                    <Typography
                        component='div'
                        className={styles.currency}>
                        <span>{subLabel}</span>
                    </Typography>
                )}
                {options.map((option, index) => (
                    <FormControlLabel
                        key={index}
                        sx={{ margin: 0, fontSize: '14px' }}
                        classes={{ label: styles.labelFormControlLabel }}
                        control={
                            <Checkbox
                                sx={{ py: '8px', px: '4px' }}
                                checked={option.value}
                                value={option.value}
                                onChange={(e: any) => onChange(e, option.data)}
                            />
                        }
                        label={option.component}
                    />
                ))}
            </>
        </FilterDropdown>
    );
}
