import { useRouter } from 'next/router';
import { AirportsInfoState } from '../../../redux/airports-info-slice';
import { useAppSelector } from '../../../redux/hooks';
import { translateAirportCode } from '../../flights/function';

export default function useAirportTranslate() {
    const router = useRouter();
    const airportsInfoState: AirportsInfoState = useAppSelector(state => state.airportsInfo);
    const { airportsInfo } = airportsInfoState;
    return (code: string, translateField: 'airport' | 'city' | 'country') => translateAirportCode(code, translateField, router.locale, airportsInfo);
}
