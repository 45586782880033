import { Collapse, FormGroup, List, ListItemButton, ListItemText } from '@mui/material';
import { useState } from 'react';
// import styles from './filter-dropdown.module.scss'
import { FilterDropdownProps } from './filter-dropdown.types';

const formControlLabelSx = { '& .MuiTypography-root': { fontSize: '16px', lineHeight: '24px', fontWeight: '700' } };

export default function FilterDropdown(props: FilterDropdownProps) {
    const { children, label } = props;
    const [open, setOpen] = useState(true);
    return (
        <List component='nav'>
            <ListItemButton
                sx={{ padding: 0 }}
                onClick={() => setOpen(!open)}>
                <ListItemText
                    sx={formControlLabelSx}
                    primary={label}
                />
                {open ? <span className='icon-chevron-light-up'></span> : <span className='icon-chevron-light-down'></span>}
            </ListItemButton>
            <Collapse
                in={open}
                timeout='auto'
                unmountOnExit>
                <List
                    component='div'
                    disablePadding>
                    <FormGroup sx={{ mt: 0.5 }}>{children}</FormGroup>
                </List>
            </Collapse>
        </List>
    );
}
