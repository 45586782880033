import { Api, Method } from '@travel-platform/commons/src/services/api';
import { AirlineTranslateResponse, PlaceSearchResponse, PlaceTranslationResponse, SearchPlacesRequest } from '../places/place-search-translate.types';

export const apiTranslatePlaceCode = (body: object) => {
    return Api<Array<PlaceTranslationResponse>>(Method.POST, '/flight/place/airport/translate', null, body);
};
export const apiSearchPlaces = (params: SearchPlacesRequest, cancelToken?: any) => {
    return Api<Array<PlaceSearchResponse>>(Method.GET, '/flight/place/search/?', params, null, cancelToken);
};
export const apiTranslateAirline = (body: object) => {
    return Api<Array<AirlineTranslateResponse>>(Method.POST, '/flight/place/airline/translate', null, body);
};
